.feed-widget {
  .feed-body {
    .feed-item {
      padding: 10px;
      display: flex;
      align-items: center;
      &:hover {
        background: $gray-100;
      }
      > .feed-icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        border-radius: 100%;
        i {
          line-height: 40px;
        }
      }
    }
  }
}
